/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import './src/i18n';

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `这个应用程序已经更新。` +
    `需要重新加载以显示最新的更新。你要重新加载吗？`
  )

  if (answer === true) {
    window.location.reload()
  }
}

