// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector) // 检测用户当前语言
  .use(initReactI18next) // 将 i18n 实例传递给 react-i18next
  .init({
    fallbackLng: 'en', // 默认语言
    interpolation: {
      escapeValue: false, // React 已经默认防止 XSS 攻击，不需要再次转义
    },
  });

export default i18n;